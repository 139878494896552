<template>
  <section class="hero-home">
    <div class="container">
      <div class="hero-home__title-wrapper" v-motion="{ ...titleAnimation }"
      >
        <Translation
          scope="global"
          tag="h1"
          class="heading--title hero-home__title"
          keypath="common.home_hero.title_copy"
        >
          <br class="hero-home__title-break" />
          <span class="hero-home__title-color">{{
            $t('common.home_hero.symptom_checker')
          }}</span>
          <span class="hero-home__title-color">
            {{ $t('common.home_hero.virtual_triage') }}</span
          >
        </Translation>
      </div>
      <div class="hero-home__cta-wrapper" v-motion="{ ...ctaAnimation }">
        <BaseButton class="hero-home__cta" @click="onButtonClick">
          <template #text>
            {{ $t('common.home_hero.cta') }}
          </template>
        </BaseButton>
      </div>
    </div>
    <div class="hero-home__container">
      <div class="hero-home__background" v-motion="{...bgImgAnimation}">
        <div class="hero-home__cards">
          <div class="hero-home__card" v-motion="getCardAnimation(0)">
            <p class="hero-home__card-title">
              {{ $t('common.home_hero.your_symptoms') }}
            </p>
            <div class="hero-home__card-content" v-motion="getContentAnimation(0)">
              <div class="hero-home__pill-container">
                <span class="hero-home__pill">{{
                  $t('common.home_hero.headache')
                }}</span>
                <span class="hero-home__pill">{{
                  $t('common.home_hero.cough')
                }}</span>
                <span class="hero-home__pill">{{
                  $t('common.home_hero.fever')
                }}</span>
              </div>
            </div>
          </div>
          <div class="hero-home__card" v-motion="getCardAnimation(1)">
            <p class="hero-home__card-title">
              {{ $t('common.home_hero.interview') }}
            </p>
            <div class="hero-home__card-content" v-motion="getContentAnimation(1)">
              <p class="hero-home__card-text">
                {{ $t('common.home_hero.interview_question') }}
              </p>
            </div>
          </div>
          <div class="hero-home__card" v-motion="getCardAnimation(2)">
            <p class="hero-home__card-title">
              {{ $t('common.home_hero.possible_conditions') }}
            </p>
            <div class="hero-home__card-content" v-motion="getContentAnimation(2)">
              <div class="hero-home__condition">
                {{ $t('common.home_hero.condition_cold') }}
                <span class="hero-home__info-icon" />
              </div>
              <div class="hero-home__condition">
                {{ $t('common.home_hero.condition_hay_fever') }}
                <span class="hero-home__info-icon" />
              </div>
            </div>
          </div>
          <div class="hero-home__card" v-motion="getCardAnimation(3)">
            <p class="hero-home__card-title">
              {{ $t('common.home_hero.next_steps') }}
            </p>
            <div class="hero-home__card-content" v-motion="getContentAnimation(3)">
              <p class="hero-home__next-step">
                {{ $t('common.home_hero.self_care') }}
              </p>
              <span class="hero-home__pill-button">{{
                $t('common.home_hero.review')
              }}</span>
            </div>
            <div class="hero-home__card-right">
              <span class="hero-home__home-icon"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { Translation } from 'vue-i18n'
import { scrollTo } from '@/utils/helpers'

const titleAnimation = {
  initial: {
    opacity: 0,
    y: -30
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
      duration: 1200,
    }
  }
}

const bgImgAnimation = {
  initial: {
    opacity: 0,
    y: 40,
    scale: 1.3
  },
  enter: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      ease: 'easeOut',
      duration: 1200,
      delay: 400
    }
  }
}

const ctaAnimation = {
  initial: {
    opacity: 0,
    y: -30
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
      duration: 1000,
      delay: 800
    }
  }
}

const getCardAnimation = (n: number) => ({
  initial: {
    opacity: 0,
    x: 20
  },
  enter: {
    opacity: 1,
    x: 0,
    transition: {
      ease: 'easeOut',
      duration: 1000,
      delay: 1800 + 400 * n
    }
  }
})

const getContentAnimation = (n: number) => ({
  initial: {
    opacity: 0,
    y: 10
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
      duration: 1000,
      delay: 1900 + 400 * n
    }
  }

})

const onButtonClick = () => {
  scrollTo('#form-section', 80)
}
</script>

<style lang="scss" scoped>
.hero-home {
  overflow: hidden;
  user-select: none;
  font-weight: 500;
  color: var(--cobalt-800);
  background-color: var(--paper-100);
  padding: 80px 0 80px 0;

  @media (min-width: $breakpoint-md) {
    padding: 96px 0 96px 0;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 128px 0 128px 0;
  }

  &__title {
    text-align: left;
    margin-bottom: 40px;

    @media (min-width: $breakpoint-lg) {
      text-align: center;
    }
  }

  &__title-color {
    color: var(--cobalt-600);
  }

  &__title-break {
    display: none;

    @media (min-width: $breakpoint-lg) {
      display: block;
    }
  }

  &__cta {
    display: block;
    width: fit-content;
    margin: 0 0 64px 0;

    @media (min-width: $breakpoint-md) {
      margin: 0 0 80px 0;
    }

    @media (min-width: $breakpoint-lg) {
      margin: 0 auto 80px auto;
    }
  }

  &__container {
    max-width: 1580px;
    margin: 0 auto;
    padding: 0 20px;

    @media (min-width: $breakpoint-md) {
      padding: 0 128px;
    }

    @media (min-width: $breakpoint-lg) {
      padding: 0 70px;
    }
  }

  &__background {
    background-image: url('/images/home/hero_mobile.png');
    background-size: cover;
    background-position: center;
    aspect-ratio: 518 / 338;
    border-radius: 24px;
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    padding: 24px 9px;

    @media (min-width: $breakpoint-md) {
      background-image: url('/images/home/hero_tablet.png');
      aspect-ratio: 512 / 398;
      padding: 12px 12px;
    }

    @media (min-width: $breakpoint-lg) {
      background-image: url('/images/home/hero.png');
      aspect-ratio: 1300 / 732;
      padding: 80px 80px;
    }
  }

  &__cards {
    display: flex;
    flex-direction: column;
    max-width: 184px;
    row-gap: 7px;

    @media (min-width: $breakpoint-md) {
      max-width: 232px;
      row-gap: 12px;
    }

    @media (min-width: $breakpoint-lg) {
      max-width: 364px;
    }
  }

  &__card {
    position: relative;
    padding: 10px;
    background-color: var(--paper-100);
    border-radius: 6px;
    width: fit-content;
    box-shadow:
      1px 383px 107px 0px rgba(48, 67, 76, 0),
      1px 245px 98px 0px rgba(48, 67, 76, 0.01),
      0px 138px 83px 0px rgba(48, 67, 76, 0.05),
      0px 61px 61px 0px rgba(48, 67, 76, 0.09),
      0px 15px 34px 0px rgba(48, 67, 76, 0.1);

    &:nth-of-type(4),
    &:nth-of-type(2) {
      width: 100%;
    }

    &:nth-of-type(3) {
      width: 78%;
    }

    @media (min-width: $breakpoint-md) {
      padding: 12px;
      border-radius: 10px;
    }

    @media (min-width: $breakpoint-lg) {
      padding: 20px;
      border-radius: 12px;
    }
  }

  &__card-title {
    color: var(--cobalt-600);
    font-weight: 600;
    font-size: 5px;
    line-height: 9px;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;

    @media (min-width: $breakpoint-md) {
      font-size: 6px;
      line-height: 12px;
      margin-bottom: 8px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 10px;
      line-height: 18px;
      margin-bottom: 10px;
    }
  }

  &__pill-container {
    display: flex;
    gap: 8px;
  }

  &__pill {
    font-size: 7px;
    padding: 4px 6px;
    background-color: var(--paper-200);
    border-radius: 5px;
    line-height: 11px;

    @media (min-width: $breakpoint-md) {
      font-size: 9px;
      line-height: 14px;
      padding: 5px 7px;
      border-radius: 6px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 14px;
      line-height: 22px;
      padding: 8px 12px;
      border-radius: 10px;
    }
  }

  &__pill-button {
    font-size: 7px;
    padding: 6px 12px;
    line-height: 11px;
    border-radius: 5px;
    background-color: var(--paper-200);

    @media (min-width: $breakpoint-md) {
      font-size: 9px;
      line-height: 14px;
      padding: 8px 16px;
      border-radius: 6px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 14px;
      line-height: 22px;
      padding: 12px 24px;
      border-radius: 10px;
    }
  }

  &__card-text {
    font-size: 7px;
    line-height: 11px;
    margin: 0;

    @media (min-width: $breakpoint-md) {
      font-size: 9px;
      line-height: 14px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__condition {
    display: flex;
    align-items: center;
    font-size: 7px;
    line-height: 12px;
    padding: 4px 0px;
    column-gap: 4px;

    &:first-of-type {
      border-bottom: 1px solid var(--paper-300);
    }

    @media (min-width: $breakpoint-md) {
      font-size: 9px;
      line-height: 15px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__info-icon {
    content: '';
    display: block;
    flex-shrink: 0;

    @include icon('@/assets/icons/info-icon-cobalt.svg', 9px, 9px);

    @media (min-width: $breakpoint-md) {
      @include icon('@/assets/icons/info-icon-cobalt.svg', 12px, 12px);
    }

    @media (min-width: $breakpoint-lg) {
      @include icon('@/assets/icons/info-icon-cobalt.svg', 18px, 18px);
    }
  }

  &__next-step {
    margin-bottom: 8px;
    font-size: 9px;
    line-height: 13px;

    @media (min-width: $breakpoint-md) {
      font-size: 12px;
      margin-bottom: 12px;
      line-height: 16px;
    }

    @media (min-width: $breakpoint-lg) {
      font-size: 18px;
      margin-bottom: 20px;
      line-height: 24px;
    }
  }

  &__card-right {
    background-color: var(--green-500);
    width: 41px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 6px 6px 0;
    display: flex;
    justify-content: center;
    padding-top: 12px;

    @media (min-width: $breakpoint-md) {
      width: 51px;
      border-radius: 0 10px 10px 0;
      padding-top: 16px;
    }

    @media (min-width: $breakpoint-lg) {
      width: 80px;
      border-radius: 0 12px 12px 0;
      padding-top: 24px;
    }
  }

  &__home-icon {
    content: '';
    display: block;
    flex-shrink: 0;

    @include icon('@/assets/icons/home-icon-white.svg', 24px, 24px);

    @media (min-width: $breakpoint-md) {
      @include icon('@/assets/icons/home-icon-white.svg', 30px, 30px);
    }

    @media (min-width: $breakpoint-lg) {
      @include icon('@/assets/icons/home-icon-white.svg', 48px, 48px);
    }
  }
}
</style>
